import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { baseUrl } from 'constants/index'

const defaultLocale = localStorage.getItem('i18nextLng') || 'fi'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${baseUrl}/web/translation/registration_page/{{lng}}`,
      customHeaders: {
        Accept: 'application/json',
      },
    },
    react: {
      useSuspense: false,
    },
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    joinArrays: false,
  })

export default i18n
