export const baseUrl = process.env.REACT_APP_BASE_URL ?? 'https://web.museo-staging.devolon.cloud'

export * from './colors'
export * from './types'
export * from './fonts'
export * from './responsive-constant'

export const HeaderFooterSRC =
  process.env.REACT_APP_ENV === 'production'
    ? {
        header: `${baseUrl}/web/web-pages/header`,
        footer: `${baseUrl}/web/web-pages/footer`,
        header_style:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_header.css',
        footer_style:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_footer.css',
        page_style:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/defer.js',
        mobiilinavi_style:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/mobiilinavi.js',
      }
    : {
        header: `${baseUrl}/web/web-pages/header`,
        footer: `${baseUrl}/web/web-pages/footer`,
        header_style:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_header.css',
        footer_style:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_footer.css',
        page_style:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/defer.js',
        mobiilinavi_style:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/mobiilinavi.js',
      }
